nav a { 
     margin-right: 1em;
     color:black; 
}

nav a.active { 
     font-weight: bold; 
     /* color:white; */
}

#jumbo{ 
     background-color:rgba(255,255,255,.5); 
     background-color: transparent;

} 

.footer {
     /* position: absolute; */
     margin-top: 400px;
     color:black;
     bottom: 0;
     width: 100%;
     height: 30px;
     background-color: rgba(255,255,255,.4);
     /* background-color: red; */



}
.white{
     background-color: rgba(255,255,255,.6);
}