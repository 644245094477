.crew-box{
    border:1px solid black;
    border-radius:1%;
    min-width:300px;
    min-height:200px;

    margin-bottom:20px;
    padding:10px;

}
li{
    text-decoration: none;
}
.logo, .photo{
    border-radius: 0px;
    min-height:200px;
    min-width:200px;
    max-height:200px;
    max-width:200px;
}
