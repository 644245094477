.list-group-item {
  color:black;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/* img{
  min-width:30px;
  height:30px;
  
} */
