html{
  min-height:100%;
}
body {
  min-height:100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./images/alaska-2438542_1920.jpg');
  /* background-image: url('./images/Spur.jpg'); */
  background-color: aqua;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.card{
  background-color: transparent;
}
.card-body{
  background-color: transparent;
}
