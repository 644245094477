.list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255,255,255,.3);
}

/* img {
  
  max-height:100px;
  max-width:200px; */
  
/* } */
.card, .card-body{
  background-color: transparent;


}