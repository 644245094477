
#main{
    /* display:flex; */
    justify-content: center;
}
#board{
    width: 80vw;
    height:80vw;
    
}
/*Responsive Styles*/


/* Smartphones (portrait) ---------------- */ 
@media only screen 
and (max-width : 320px)
{   
    #counter{
        font-size: 6px;
    }
    #board{
        width: 80vw;
        height: 80vw;
    }
}
@media only screen 
and (min-width : 321px)
and (max-width : 450px)
{
    #board{
        width: 80vw;
        height: 80vw;
    }
}


/* Smartphones (landscape) ---------------- */
@media only screen 
and (min-width : 321px)
and (max-width : 767px)
and (max-height : 560px)
{
    #board{
        width: 80vh;
        height:80vh;
    }
}





/* Tablets (portrait) -------------------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait)
{
    #board{
        width: 80vw;
        height: 80vw;
    }
}


/* Tablets (landscape) ------------------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape)
{   
    #board{
        width: 80vh;
        height: 80vh;
    }
}

/* Old Desktops and laptops ------------------ */
@media only screen 
and (min-width : 1025px) 
{
    #board{
        width: 80vh;
        height: 80vh;
    }
}

/* Desktops ------------------ */
@media only screen 
and (min-width : 1201px) 
{
    #board{
        width: 80vh;
        height: 80vh;
    }
}



.outerBox{

height:90%;
width:19.5%;
/* border:1px solid black; */
/* border-radius:5px; */
display: inline-block;
position:relative;
background-color: transparent;
perspective: 1000px;
}



.innerBox{
    height:100%;
    width:100%;
    /* border: 1px solid pink; */
    /* border-radius:5px; */
    
    background-color: transparent;
    position:absolute;
    color:darkblue;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    transform-style: preserve-3d;
    transition: transform 0.5s;
    
}

.cardFront, .cardBack{
    height:100%;
    width:100%;
    backface-visibility: hidden;
    border:1px solid rgb(2, 44, 2);
    border-radius:5px;
    position: absolute;
    color:black;
    
}

.cardFront{
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotateY(180deg);
    /* background-color:rgb(223, 89, 223) */
}

.cardBack{
    /* background-color: rgba(255, 255, 255, .8); */
    background-image: url('./imgs/memoryCard.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
}

.uncovered .innerBox{
    transform: rotateY(180deg);
}


.img1{

    background-image: url('./imgs/img1.jpg');
}
.img2{
    background-image: url('./imgs/img2.jpg');
}
.img3{
    background-image: url('./imgs/img3.jpg');
}
.img4{
    background-image: url('./imgs/img4.jpg');
}
.img5{
    background-image: url('./imgs/img5.jpg');
}
.img6{
    background-image: url('./imgs/img6.jpg');
}
.img7{
    background-image: url('./imgs/img7.jpg');
}
.img8{
    background-image: url('./imgs/img8.jpg');
}
.img9{
    background-image: url('./imgs/img9.jpg');
}
.img10{
    background-image: url('./imgs/img10.jpg');
}
.img11{
    background-image: url('./imgs/img11.jpg');
}
.img12{
    background-image: url('./imgs/img12.jpg');
}
